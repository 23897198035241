
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ElTable } from "element-plus";
import JwtService from "@/core/services/JwtService";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import moment from "moment";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {},

  setup(props, { emit }) {
    const store = useStore();
    const transport_mode_data = ref([]);
    const transportation_for_data = ref([]);
    const is_client_transport_data = ref([
      {
        id: 0,
        name: "No",
      },
      {
        id: 1,
        name: "Yes",
      },
    ]);

    const formRef = ref<null | HTMLFormElement>(null);
    const formAddProductRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const supplier_data = ref<Array<Supplier>>([]);
    const client_data = ref<any>([]);
    const consignee_branch_data = ref([]);
    const po_data = ref([]);

    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref<Product[]>([]);
    const dialogSelectProductTableVisible = ref(false);
    const myData = ref<Array<any>>([]);
    const tableData = ref<Array<Product>>([]);
    const selectedOrdersProducts = ref<Array<any>>([]);

    interface Product {
      do_details_id: string;
      dispatch_order_no: string;
      consignee_warehouese: "";
      product_name: string;
      dispatch_qty: string;
      actual_quantity: string;
      uom: string;
      isCheck: boolean
    }
    const router = useRouter();

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    const formData = ref({
      transportation_for_select: "",
      is_client_transport_select: "",
      transporter_select: "",
      transporter_branch_select: "",
      other_company_name: "",
      transporter_name: "",
      contact_detail: "",
      transport_mode_select: "",
      vehicle_no: "",
      source: "",
      destination: "",
      sjvd_date: new Date(),
      estimated_arrival_date: new Date(),
      driver_name: "",
      contact_no: "",
      remarks: "",
    });

    const formProductData = ref({
      client_select: "",
      client_branch_select: "",
    });

    const rules = ref<any>({});
    const productRules = ref<any>({});

    onMounted(async () => {
      getTransportModes();
      getTransportType();
      setCurrentPageBreadcrumbs("New Transportation Note", []);
    });

    const setCompanyData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      debugger;
      let product_list: any = [];
      myData.value.map((item) => {
        product_list.push({
          do_detail_id: item.do_details_id,
          lr_no: item.lr_no,
          actual_qty: item.actual_quantity,
          fare_rate: item.fare_rate,
        });
      });
      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Select One Dispatch Order.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      let requestData = { 
        user_id : user.user_id,
        doc_type_id : data.transportation_for_select,
        supplier_transport_yn : data.is_client_transport_select,
        transporter_company_id : data.is_client_transport_select === 0 ? data.transporter_select : "",
        transporter_company_branch_id : data.is_client_transport_select === 0
            ? data.transporter_branch_select: "",
        other_transporter_company_name : data.is_client_transport_select === 1 ? data.transporter_name : "",
        other_transporter_contact_no : data.is_client_transport_select === 1 ? data.contact_detail : "",
        transport_mode_id : data.transport_mode_select,
        transporter_vehicle_no : data.vehicle_no,
        transport_sourcce : data.source,
        transport_destination : data.destination,
        transport_start_time : moment(data?.sjvd_date)?.format("YYYY-MM-DD HH:mm:ss"),
        transport_arrival_time : moment(data?.estimated_arrival_date)?.format("YYYY-MM-DD HH:mm:ss"),
        transporter_driver_name : data.driver_name,
        transporter_driver_contact_no : data.contact_no,
        remarks : data.remarks,
        product_details: product_list
    }
      console.log('final request data',requestData);
      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_TRANSPORTATION_NOTE, requestData)
          .then(({ data }) => {
            loading.value = false;
            console.log(data);
            if (data) {
              setTimeout(() => {
                loading.value = false;

                Swal.fire({
                  html: ""+
                      data?.transport_note_no+
                      "<br>Transport note created successfully!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "apps-transportation-note-listing" });
                });
              }, 2000);
            } else {
              Swal.fire({
                text: "Error!",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };
    const addProduct = (data) => {
      loading.value = false;
      myData.value = myData.value.concat(selectedOrdersProducts.value);
      resetProductForm();
    };

    const submit = async () => {
      debugger;
      loading.value = true;
      rules.value = {
        is_client_transport_select: [
          {
            required: true,
            message: "Client Vehicle/Transport For is required",
            trigger: "change",
          },
        ],
        transportation_for_select: [
          {
            required: true,
            message: "Transport For is required",
            trigger: "change",
          },
        ],
        transporter_select: [
          {
            required: true,
            message: "Transporter is required",
            trigger: "change",
          },
        ],
        transporter_branch_select: [
          {
            required: true,
            message: "Transporter Branch is required",
            trigger: "change",
          },
        ],
        other_company_name: [
          {
            required: true,
            message: "Company Name is required",
            trigger: "change",
          },
        ],
        contact_detail: [
          {
            required: formData.value.is_client_transport_select == '0' ? 
            true : false,
            message: "Contact Detail is required",
            trigger: "change",
          },
        ],
        transport_mode_select: [
          {
            required: true,
            message: "Transport Mode is required",
            trigger: "change",
          },
        ],
        vehicle_no: [
          {
            required: true,
            message: "Vehicle No is required",
            trigger: "change",
          },
        ],
        source: [
          {
            required: formData.value.is_client_transport_select == '0' ? 
            true : false,
            message: "Source is required",
            trigger: "change",
          },
        ],
        destination: [
          {
            required: formData.value.is_client_transport_select == '0' ? 
            true : false,
            message: "Destination is required",
            trigger: "change",
          },
        ],
        sjvd_date: [
          {
            required: true,
            message: "TN Date is required",
            trigger: "change",
          },
        ],
        estimated_arrival_date: [
          {
            required: formData.value.is_client_transport_select == '0' ? 
            true : false,
            message: "Estimated Arrival is required",
            trigger: "change",
          },
        ],
        driver_name: [
          {
            required: formData.value.is_client_transport_select == '0' ? 
            true : false,
            message: "Driver Name is required",
            trigger: "change",
          },
        ],
        contact_no: [
          {
            required: formData.value.is_client_transport_select == '0' ? 
            true : false,
            message: "Contact No is required",
            trigger: "change",
          },
        ],
        transporter_name: [
          {
            required: formData.value.is_client_transport_select == '0' ? 
            true : false,
            message: "transporter name is required",
            trigger: "change",
          },
        ]
      };

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      setTimeout(() => {
        formRef?.value?.validate(async (valid) => {
          if (valid) {
            await setCompanyData(formData.value);
          } else {
            loading.value = false;
            return false;
          }
        });
      }, 300);
    };
    const submitProduct = async () => {
      debugger;
      loading.value = true;
      productRules.value = {
        client_select: [
          {
            required: true,
            message: "Client is required",
            trigger: "change",
          },
        ],
        client_branch_select: [
          {
            required: true,
            message: "Branch is required",
            trigger: "change",
          },
        ],
      };

      if (!formAddProductRef.value) {
        loading.value = false;
        return;
      }

      setTimeout(() => {
        formAddProductRef?.value?.validate(async (valid) => {
          if (valid) {
            if (selectedOrdersProducts?.value?.length <= 0) {
              console.log("validation failed");
              Swal.fire({
                text: "Please select at least one actual qty.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              loading.value = false;
              return;
            }

            let editedrowUnchecked = false
            for(let product of tableData.value){
              if(product.actual_quantity !== '' &&  !product.isCheck){
                  editedrowUnchecked = true;
                  break;
              }
            }
          
            if(editedrowUnchecked){
                Swal.fire({
                  title: "Are you sure?",
                  text: "Please make sure there are some edited row(s) are not selected!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, Submit!",
                  cancelButtonText:'Select Again'
                }).then((result) => {
                  if (result.isConfirmed) {
                    addProduct(formProductData.value);
                  } 
                });
                loading.value = false;
            }else{
              await addProduct(formProductData.value);
            }
          } else {
            loading.value = false;
            return false;
          }
        });
      }, 300);
    };

    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }
      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          supplier_data.value = data;
          client_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getBranchList(type) {
      let values = {
        // company_id: formProductData.value["client_select"],
      };
      if (type === "product") {
        values = {
          company_id: formProductData.value["client_select"],
          company_branch_id: 0,
          page: 1,
          records_per_page: 1000,

        };
      } else if (type === "form") {
        values = {
          company_id: formData.value["transporter_select"],
          company_branch_id: 0,
          page: 1,
          records_per_page: 1000,

        };
      }
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          consignee_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getTransportModes() {
      var user = JSON.parse(JwtService.getToken());

      let params = {
        user_id: user.user_id,
      };
      await store
        .dispatch(ActionsFi.CUST_GET_TRANSPORT_MODE_DATA, params)
        .then(({ data }) => {
          debugger;
          transport_mode_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getTransportType() {
      var user = JSON.parse(JwtService.getToken());

      let params = {
        user_id: user.user_id,
      };
      await store
        .dispatch(ActionsFi.CUST_GET_LOGISTIC_MANAGEMENT_DOC_TYPE, params)
        .then(({ data }) => {
          debugger;
          transportation_for_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }


    async function getPOList() {
      let params = {
        supplier_company_id: formData.value["supplier_select"],
        supplier_company_branch_id: formData.value["supplier_warehouse_select"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_PNDING_PO_BY_CLIENT_ID, params)
        .then(({ data }) => {
          debugger;
          po_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const onCancle = () => {
      multipleTableRef.value!.clearSelection();
      multipleSelection.value = [];
      dialogSelectProductTableVisible.value = false;
    };


    async function getTableData() {
      let params = {};
      let API = '';
      if(formData.value.transportation_for_select == '2'){
       API = ActionsFi.CUST_GET_PENDING_PO_LIST
       params = {
        supplier_company_id: formProductData.value["client_select"],
        supplier_company_branch_id:
          formProductData.value["client_branch_select"],
      };
      }else if(formData.value.transportation_for_select == '1'){
        API = ActionsFi.CUST_GET_SALES_PENDING_DISPATCH_ORDER_LIST
        params = {
          customer_company_id: formProductData.value["client_select"],
          customer_company_branch_id:
          formProductData.value["client_branch_select"],
      };
      }
      debugger;
      await store
        .dispatch(API, params)
        .then(({ data }) => {
          debugger;
          data.map((product) => {
            product.dispatch_qty = product.dispatch_qty + " " + product.uom;
            product.actual_quantity = '';
            product.lr_no= '';
            product.fare_rate = '';
            product.isCheck = false;
          });
          tableData.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const onCheckAndInput = (selectedRows, isFormInput = false, filedName = '') => {
      let alreadySelectedRowIndex = myData.value.findIndex((row)=> {return row.do_details_id === selectedRows.do_details_id});
      if(alreadySelectedRowIndex !== -1){
          selectedRows.isCheck = false;
          selectedRows.actual_quantity = '';
          selectedRows.lr_no = '';
          selectedRows.fare_rate = '';
          Swal.fire({
          text: "Sorry this product data is already selected",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }

      if ((selectedRows.actual_quantity.trim() === "" || selectedRows.lr_no.trim() === "" 
          || selectedRows.fare_rate.trim() === "") && !isFormInput) {
        Swal.fire({
          text: "Please enter data in the first three fields. These are required fields and then select",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        selectedRows.isCheck = false;
      } else if (isFormInput) {
        if(['lr_no'].includes(filedName)){       
          removeFormSelectedOffers(selectedRows); 
        } else{
          validateNumber(selectedRows, filedName); 
          removeFormSelectedOffers(selectedRows);
        }
      } else if (!selectedRows.isCheck && !isFormInput) {
        removeFormSelectedOffers(selectedRows);
      } else {
        selectedOrdersProducts.value.push(selectedRows
        );
      }
    };

    const validateNumber = (selectedRows, filedName) => {
      debugger;
      if (/^\d*\.?\d*$/.test(selectedRows[filedName])) {
        if(filedName != 'fare_rate' && selectedRows[filedName] == 0){
          selectedRows[filedName] = selectedRows[filedName].slice(0, -1);
          return;
        }
        return;
      } else if (selectedRows[filedName] == "") {
        return;
      } 
      else {
        selectedRows[filedName] = selectedRows[filedName].slice(0, -1);
      }
    };

    const removeFormSelectedOffers = (selectedRows) => {
      selectedRows.isCheck = false;
      let index = selectedOrdersProducts.value
        .map((deteRow) => {
          return deteRow.do_details_id;
        })
        .indexOf(selectedRows?.do_details_id);
      index !== -1 ? selectedOrdersProducts.value.splice(index, 1) : null;
    };

    function deleteRow(row) {
      var index = myData.value.map((deteRow) => {return deteRow?.do_details_id}).indexOf(row?.do_details_id);
      myData.value.splice(index, 1);
    }

    const onMobileEnter = () => {
      debugger;
    };

    const openDilogue = () => {
      let transportForSelect = false;
      for(let doc of transportation_for_data.value){
        if(doc['doc_type_id'] === formData.value.transportation_for_select){
          transportForSelect = true;
          break;
        }
      }
      if(transportForSelect){
      dialogSelectProductTableVisible.value = true;
      }else{
        Swal.fire({
          text: "Please select Transportation for",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return;
      }
    };


    const resetForm = () => {
      debugger;
      formData.value = {
        transportation_for_select: "",
        is_client_transport_select: "",
        transporter_select: "",
        transporter_branch_select: "",
        other_company_name: "",
        transporter_name: "",
        contact_detail: "",
        transport_mode_select: "",
        vehicle_no: "",
        source: "",
        destination: "",
        sjvd_date: new Date(),
        estimated_arrival_date: new Date(),
        driver_name: "",
        contact_no: "",
        remarks: "",
      };
      rules.value = {};
      tableData.value = [];
      myData.value = [];
    };

    const resetProductForm = () => {
      formProductData.value = {
        client_select: "",
        client_branch_select: "",
      };
      tableData.value= [];
      selectedOrdersProducts.value = [];
      dialogSelectProductTableVisible.value = false;
    }
    function onlyLettersAndSpaces(str) {
      if (/^[A-Za-z\s]*$/.test(formData.value[str])) {
        return;
      }else{
        formData.value[str] = formData.value[str].slice(0, -1);
      }
}


    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      supplier_data,
      getCompanyList,
      consignee_branch_data,
      getBranchList,
      dialogSelectProductTableVisible,
      tableData,
      myData,
      multipleTableRef,
      multipleSelection,
      onCancle,
      getTableData,
      openDilogue,
      transport_mode_data,
      po_data,
      getPOList,
      resetForm,
      getTransportModes,
      is_client_transport_data,
      transportation_for_data,
      formProductData,
      client_data,
      productRules,
      formAddProductRef,
      submitProduct,
      resetProductForm,
      onCheckAndInput,
      deleteRow,
      onMobileEnter,
      onlyLettersAndSpaces
    };
  },
});
